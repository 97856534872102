<template>
  <div>
    <v-tabs grow show-arrows @change="change" class="mb-6 d-sm-flex d-none" v-model="register" >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab of tabs" :key="tab" >
        {{ tab }}
      </v-tab>
    </v-tabs>
    <v-tabs show-arrows @change="change" class="mb-6 d-sm-none" v-model="register" >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab of tabs" :key="tab" >
        {{ tab }}
      </v-tab>
    </v-tabs>
  </div>

</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "Message",
  data(){
    return {
      acTab: this.register
    }
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    tabs: {
      type: [Array, Object],
      default(){
        return []
      }
    },
    register: Number
  },
  methods:{
    change(){
      this.$emit('changed', this.register)
    }
  }
}
</script>
